<template>
    <div>
        <NuxtLayout name="default">
            <div class="flex flex-row h-full">
                <div
                    v-if="menu"
                    class="flex flex-col h-full bg-gray-800 p-1 transition-all duration-300 rounded-lg">
                    <h2 class="mx-3 my-2 text-gray-200 text-2xl font-bold">
                        Nastavení
                    </h2>
                    <MenuButton
                        title="Profil"
                        icon="user"
                        @click="close"
                        link="/settings/profile" />
                    <MenuButton
                        title="Firemní profily"
                        icon="briefcase"
                        @click="close"
                        link="/settings/businesses" />
                    <MenuButton
                        title="Zabezpečení"
                        icon="lock"
                        @click="close"
                        link="/settings/security" />
                    <MenuButton
                        title="Moduly"
                        icon="puzzle-piece"
                        @click="close"
                        link="/settings/modules" />
                    <MenuButton
                        title="Externí kalendář"
                        icon="calendar"
                        @click="close"
                        link="/settings/external-calendar" />
                    <MenuButton
                        title="Platba a plán"
                        icon="credit-card"
                        @click="close"
                        link="/settings/billing" />
                </div>
                <div
                    v-else
                    class="flex flex-col h-full bg-gray-800 p-1 transition-all duration-300 rounded-lg">
                    <font-awesome-icon
                        class="text-gray-200 text-2xl font-bold cursor-pointer m-2"
                        icon="bars"
                        @click="menu = !menu" />
                </div>
                <div
                    v-if="!menu || !small"
                    class="flex-grow h-full py-2 px-5 min-h-full overflow-y-auto">
                    <slot />
                </div>
            </div>
        </NuxtLayout>
    </div>
</template>
<script lang="ts">
import MenuButton from "~/components/home/MenuButton.vue";
export default defineNuxtComponent({
    components: {
        MenuButton,
    },
    setup() {
        const menu = ref(true);

        return {
            menu,
        };
    },
    data() {
        const small = window.innerWidth < 768;
        return {
            small,
        };
    },
    methods: {
        close() {
            if (process.client) {
                if (window.innerWidth < 768) this.menu = false;
            }
        },
    },
});
</script>
